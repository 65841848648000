import { cn } from "@/lib/utils";
import { X } from "lucide-react";
import * as React from "react";

interface FloatingContainerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  overlayClassName?: string;
}

const FloatingContainer = React.forwardRef<
  HTMLDivElement,
  FloatingContainerProps
>(({ isOpen, onClose, children, className, overlayClassName }, ref) => {
  if (!isOpen) return null;

  return (
    <div className="sdk-floating-container">
      <div
        className={cn(
          "fixed inset-0 z-[999999] bg-black/80 sdk-overlay",
          overlayClassName
        )}
        onClick={onClose}
      />
      <div
        ref={ref}
        className={cn(
          "fixed left-[50%] top-[50%] z-[999999] grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-white p-6 shadow-lg duration-200 sm:rounded-lg sdk-content",
          className
        )}
      >
        {children}
        <button
          onClick={onClose}
          className="absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none sdk-close-button"
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </button>
      </div>
    </div>
  );
});

FloatingContainer.displayName = "FloatingContainer";

const FloatingContainerHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 text-center sm:text-left sdk-header",
      className
    )}
    {...props}
  />
);

const FloatingContainerFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 sdk-footer",
      className
    )}
    {...props}
  />
);

const FloatingContainerTitle = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) => (
  <h2
    className={cn(
      "text-lg font-semibold leading-none tracking-tight sdk-title",
      className
    )}
    {...props}
  />
);

const FloatingContainerDescription = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) => (
  <p
    className={cn("text-sm text-gray-500 sdk-description", className)}
    {...props}
  />
);

export {
  FloatingContainer,
  FloatingContainerDescription,
  FloatingContainerFooter,
  FloatingContainerHeader,
  FloatingContainerTitle,
};
