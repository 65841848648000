import React from "react";

interface PlayIconProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
}

export default function PlayIcon({
  width = 18,
  height = 20,
  fill = "white",
  className = "",
}: PlayIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.8594 8.93241C17.8594 9.50976 17.8594 10.9531 16.8594 11.5305L2.79248 19.652C1.79248 20.2294 0.54248 19.5077 0.54248 18.353L0.54248 2.10992C0.54248 0.955218 1.79248 0.233531 2.79248 0.810881L16.8594 8.93241Z"
        fill={fill}
      />
    </svg>
  );
}
