import { AnalyticsEvent } from "@/types";

export class AnalyticsTracker {
  projectId: string;
  endpoint: string;
  queue: AnalyticsEvent[];
  batchSize: number;
  flushInterval: number;

  constructor(projectId: string, endpoint: string) {
    this.projectId = projectId;
    this.endpoint = endpoint;
    this.queue = [];
    this.batchSize = 10;
    this.flushInterval = 5000;

    setInterval(() => this.flush(), this.flushInterval);
  }

  track(eventName: string, properties: object = {}) {
    const event: AnalyticsEvent = {
      projectId: this.projectId,
      eventName,
      properties,
      timestamp: new Date().toISOString(),
      sessionId: this.getSessionId(),
      userId: this.getUserId(),
      userAgent: navigator.userAgent,
      pathname: window.location.href,
    };

    this.queue.push(event);

    if (this.queue.length >= this.batchSize) {
      this.flush();
    }
  }

  async flush() {
    if (this.queue.length === 0) return;

    const events = [...this.queue];
    this.queue = [];

    try {
      await fetch(this.endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ events }),
      });
    } catch (error) {
      this.queue = [...events, ...this.queue];
      console.error("Failed to send analytics:", error);
    }
  }

  getSessionId() {
    let sessionId = sessionStorage.getItem("analytics_session_id");
    if (!sessionId) {
      sessionId = Math.random().toString(36).substring(2);
      sessionStorage.setItem("analytics_session_id", sessionId);
    }
    return sessionId;
  }

  getUserId() {
    let userId = localStorage.getItem("analytics_user_id");
    if (!userId) {
      userId = Math.random().toString(36).substring(2);
      localStorage.setItem("analytics_user_id", userId);
    }
    return userId;
  }
}
