import { Button } from "@/components/ui/button";
import {
  FloatingContainer,
  FloatingContainerDescription,
  FloatingContainerHeader,
  FloatingContainerTitle,
} from "@/components/ui/floating-container";
import { Input } from "@/components/ui/input";
import { API_URL } from "@/lib/const";
import { cn } from "@/lib/utils";
import { FormData } from "@/types";
import { Loader2, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useAnalytics } from "./context/analytics-context";

interface LeadCaptureModalProps {
  isRtl: boolean;
  formData: FormData;
  isOpen: boolean;
  onClose: () => void;
  apiKey: string;
  portalContainer?: HTMLElement;
}

export function LeadCaptureModal({
  isRtl,
  formData: formProps,
  isOpen = false,
  onClose = () => {},
  apiKey,
  portalContainer,
}: LeadCaptureModalProps) {
  const { trackEvent, userId } = useAnalytics();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate form fields
    const newErrors = {
      fullName: formData.fullName.trim() === "" ? "Full name is required" : "",
      email: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
        ? "Invalid email address"
        : "",
      phone: formData.phone.trim() === "" ? "Phone number is required" : "",
    };
    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === "")) {
      setLoading(true);
      try {
        trackEvent("sdk_lead_capture", {
          fullName: formData.fullName,
          email: formData.email,
          phone: formData.phone,
        });
        await fetch(`${API_URL}/lead`, {
          method: "POST",
          body: JSON.stringify({ ...formData, apiKey, userId }),
        });
        setSubmitted(true);
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onDialogClose = () => {
    setSubmitted(false);
    setLoading(false);
    setFormData({
      fullName: "",
      email: "",
      phone: "",
    });
    onClose();
  };

  useEffect(() => {
    return () => {
      onClose();
    };
  }, []);

  return (
    <FloatingContainer
      isOpen={isOpen}
      onClose={onDialogClose}
      className="sm:max-w-md w-[calc(100%-2rem)] rounded-2xl md:px-12 bg-white"
    >
      <button
        onClick={onDialogClose}
        className="absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100"
      >
        <X className="h-4 w-4" />
        <span className="sr-only">Close</span>
      </button>

      {!submitted ? (
        <>
          <FloatingContainerHeader>
            <FloatingContainerTitle className="text-2xl font-bold text-center">
              {formProps.title}
            </FloatingContainerTitle>
            <FloatingContainerDescription
              className={isRtl ? "text-right" : "text-left"}
              dir={isRtl ? "rtl" : "ltr"}
            >
              {formProps.subTitle}
            </FloatingContainerDescription>
          </FloatingContainerHeader>
          <form onSubmit={handleSubmit} className="space-y-4">
            <Input
              placeholder={isRtl ? "שם מלא" : "Full name"}
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              required
              disabled={loading}
              className={cn(
                errors.fullName ? "border-red-500" : "",
                isRtl ? "text-right" : "text-left"
              )}
            />
            {errors.fullName && (
              <p className="text-red-500 text-sm">{errors.fullName}</p>
            )}
            <Input
              type="tel"
              placeholder={isRtl ? "מספר טלפון" : "Phone number"}
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              required
              disabled={loading}
              className={cn(
                errors.phone ? "border-red-500" : "",
                isRtl ? "text-right" : "text-left"
              )}
            />
            {errors.phone && (
              <p className="text-red-500 text-sm">{errors.phone}</p>
            )}
            <Input
              type="email"
              placeholder={isRtl ? "אימייל" : "Email"}
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              disabled={loading}
              className={cn(
                errors.email ? "border-red-500" : "",
                isRtl ? "text-right" : "text-left"
              )}
            />
            {errors.email && (
              <p className="text-red-500 text-sm">{errors.email}</p>
            )}
            <div className="text-center">
              <Button
                type="submit"
                dir={isRtl ? "rtl" : "ltr"}
                className="w-full bg-black text-white hover:bg-black/90"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    {isRtl ? "שליחה..." : "Sending..."}
                  </>
                ) : isRtl ? (
                  "שליחה"
                ) : (
                  "Send"
                )}
              </Button>
            </div>
          </form>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center text-center">
          <div className="mb-4 rounded-full border-2 border-black p-2">
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <FloatingContainerTitle className="text-xl font-semibold">
            {formProps.thxTitle}
          </FloatingContainerTitle>
          <FloatingContainerDescription className="mb-6">
            {formProps.thxSubTitle}
          </FloatingContainerDescription>
          <Button
            onClick={onDialogClose}
            className="w-full bg-black text-white hover:bg-black/90"
          >
            {formProps.thxCta}
          </Button>
        </div>
      )}
    </FloatingContainer>
  );
}
