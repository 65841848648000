import { AnalyticsTracker } from "@/lib/analytics";
import React, { createContext, useContext } from "react";
export const AnalyticsContext = createContext<{
  trackEvent: (eventName: string, properties?: object) => void;
  userId: string;
}>({
  trackEvent: () => {},
  userId: "",
});

const ANALYTICS_ENDPOINT = "https://webreels.io/api/analytics";

export const AnalyticsProvider = ({
  children,
  projectId,
  apiKey,
}: {
  children: React.ReactNode;
  projectId: string;
  apiKey: string;
}) => {
  const tracker = new AnalyticsTracker(projectId, ANALYTICS_ENDPOINT);

  const trackEvent = (eventName: string, properties: object = {}) => {
    if (process.env.NODE_ENV === "development") {
      console.log("trackEvent", eventName, properties);
      return;
    }

    tracker.track(eventName, { ...properties, apiKey });
  };

  return (
    <AnalyticsContext.Provider
      value={{ trackEvent, userId: tracker.getUserId() }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};
