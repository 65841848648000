import { Button } from "@/components/ui/button";
import React from "react";
import Play from "./icons/Play";

interface FABProps {
  icon?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  imageUrl?: string;
}

const FAB: React.FC<FABProps> = ({
  icon = <Play />,
  onClick,
  className = "",
  imageUrl = "",
}) => {
  return (
    <Button
      className={`flex items-center justify-center w-full h-full rounded-full ${className}`}
      onClick={onClick}
      size="icon"
    >
      {imageUrl && (
        <div className="absolute inset-0 rounded-full overflow-hidden">
          <img
            loading="lazy"
            src={imageUrl}
            alt="FAB Background"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-20 rounded-full" />
        </div>
      )}
      <div className="relative z-10">{icon}</div>
    </Button>
  );
};

export default FAB;
