import { AnalyticsProvider } from "@/components/context/analytics-context";
import { VideoFeedWidget } from "@/components/video-feed-widget";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { getProjectSDK } from "./lib/api";

interface WidgetConfig {
  apiKey: string;
  position?: "bottom-right" | "bottom-left";
  variant?: "widget" | "story" | "lightbox";
  theme?: {
    primaryColor: string;
    textColor: string;
  };
}

export const initialize = async (config: WidgetConfig) => {
  const container = document.createElement("div");
  container.id = "webreels-sdk-root";
  container.classList.add("webreels");
  document.body.appendChild(container);

  if (!config.apiKey) {
    throw new Error("WebReels: API key is required");
  }

  const { data: sdkData } = await getProjectSDK(config.apiKey);

  const root = createRoot(container);
  root.render(
    <div dir="ltr" className="relative z-[9999999999]">
      <AnalyticsProvider projectId={sdkData.id} apiKey={config.apiKey}>
        <VideoFeedWidget
          {...config}
          variant="widget"
          data={sdkData}
          apiKey={config.apiKey}
          container={container}
        />
      </AnalyticsProvider>
    </div>
  );

  return () => {
    root.unmount();
  };
};
